<template>
    <div class="offer_data_row">
        <p class="title">{{title}}</p><div class="value" v-html="rowValue"></div>
    </div>
</template>

<script>
export default {
    props: ['title', 'value'],
    computed: {
        rowValue() {
            if (this.value.length == 0) {
                return '[Не заполнено]';
            } else {
                return this.value;
            }
        }
    }
}
</script>


<style lang="scss" scoped>
    .offer_data_row {
        display: block;
        margin-bottom: 25px;
        p.title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
            display: block;
            background: #f5f5f5;
            padding: 7px 9px;
            width: 100%;
        }
        .value {
            display: block;
            width: 100%;
            padding: 0 9px;
        }
    }
</style>