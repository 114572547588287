<template>
    <aside class="sidebar">
        <ul>
            <li><router-link to="/offers">Мои КП</router-link></li>
            <li><router-link to="/edit">Создать КП</router-link></li>
        </ul>
    </aside>
</template>

<style scoped lang="scss">
    .sidebar {
        flex: 0 0 310px;
        max-width: 310px;
        width: 310px;
        padding-top: 25px;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            max-width: 280px;
            border-right: 1px #E0E0E0 solid;
            li {
                display: flex;
                a {
                    font-size: 18px;
                    color: black;
                    line-height: 1.3;
                    text-decoration: none;
                    display: flex;
                    min-height: 51px;
                    border-bottom: 1px #E0E0E0 solid;
                    width: 100%;
                    height: 100%;
                    flex: 0 0 100%;
                    align-items: center;
                    padding-left: 18px;
                    transition: .1s;
                }
                &.active {
                    background-color: #F6F6E1;
                }
                &:hover {
                    background-color: #F6F6E1;
                    a {
                        padding-left: 21px;
                    }
                }
            }
        }
    }
</style>