<template>
  <h1 v-html="formTitle"></h1>
  <div class="content_block">
    <label class="services_types_nav" v-if="mode  == 'new'">
      <router-link
        class="button"
        :to="'/edit?service_id=' + service.id"
        v-for="service in services"
        :key="service.id"
        >{{ service.title }}</router-link
      >
    </label>
  </div>
  <offer-form
    :service_id="selectedForm"
    :form_fields="formFields"
    :form_values="formValues"
    :mode="mode"
    v-if="selectedForm"
  ></offer-form>
</template>

<script>
import OfferForm from "../../components/layouts/OfferForm.vue";


export default {
  data() {
    return {
      selectedForm: this.$route.query.service_id,
      services: [],
      formTitle: "Новое коммерческое предложение",
      formFields: null,
      formValues: null,
      mode: 'new'
    };
  },
  props: ["title"],
  components: {
    "offer-form": OfferForm
  },
  methods: {
    loadServiceForm_empty(route) { // Загрузка пустых полей для создания КП (в зависимости от выбранной услуги)
      this.selectedForm = route.query.service_id;
      if (route.query.service_id) {
        this.formTitle =
          "<span>Новое КП: </span>" +
          this.services[route.query.service_id - 1].title;
        this.formFields = JSON.parse(
          this.services[route.query.service_id - 1].fields
        );
      }
    },
    async loadServiceFormByID(id) { // Загрузка полей для редактирования КП (по его ID)
      // console.log(id);

      await fetch(this.$store.state.rootPath + "/ajax/getOfferInfo.php?id=" + id, {
        method: "GET",
        headers: { "content-type": "appication/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          const offerData = data.message[0];
          // this.offerManagerId = offerData.manager;
          this.selectedForm = offerData.service_id;
          // console.log(this.selectedForm);
          // this.loadServiceData(this.offerServiceId);
          this.formValues = offerData.fields;
          this.formFields = JSON.parse(this.services[this.selectedForm - 1].fields);
          this.formTitle = "<span>Редактирование КП: </span>" + JSON.parse(this.formValues).company_name;

        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    async checkMode(route) { // Определение режима редактирования КП (создание/обновление)

      await fetch(this.$store.state.rootPath + "/ajax/getService.php", {
        method: "GET",
        headers: { "content-type": "appication/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          //console.dir(JSON.parse(data.message[2].fields));
          this.services = data.message;
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });

      if (route.query.offer_id) {
        this.mode = 'upd';
        this.loadServiceFormByID(route.query.offer_id);
      } else {
        this.mode = 'new';
        this.loadServiceForm_empty(route);
        this.formValues = null;
      }
    }
  },
  watch: {
    $route(newRoute) {
      this.checkMode(newRoute);
    },
  },
  created() {
    fetch(this.$store.state.rootPath + "/ajax/getService.php", {
      method: "GET",
      headers: { "content-type": "appication/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        //console.dir(JSON.parse(data.message[2].fields));
        this.services = data.message;
      })
      .catch(function (err) {
        console.log("Error: ", err);
      });

    this.checkMode(this.$route);
  },
};
</script>


<style lang="scss">
.content {
  flex: 0 0 890px;
  max-width: 890px;
  width: 890px;
  padding-top: 26px;
  h1 {
    span {
      color: gray;
    }
  }
  &_block {
    margin-bottom: 57px;
    .services_types_nav {
      display: flex;
    }
  }
}
</style>