<template>
  <h1>Мои КП</h1>
  <ul class="offer_list">
    <ui-offer-list-item
      v-for="offer in $store.state.offers"
      :key="offer.id"
      :company_name="offer.company_name"
      :service_id="offer.service_id"
      :manager_id="offer.manager_id"
      :offer_id="offer.offer_id"
    ></ui-offer-list-item>
  </ul>
</template>

<script>
export default {
  methods: {
    loadOffers(manager_id) {
      fetch(this.$store.state.rootPath + "/ajax/getOffersList.php?id=" + manager_id, {
        method: "GET",
        headers: { "content-type": "appication/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          const results = [];
          data.message.forEach(function (item, indx) {
            let offer_data = JSON.parse(item.fields);
            results.push({
              index: indx,
              offer_id: item.id,
              company_name: offer_data.company_name,
              manager_id: item.manager,
              service_id: item.service_id
            });
          });

        // console.log(results);
          this.$store.state.offers = results;
        //   console.log(this.$store.state.offers);
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
  },
  created() {
    this.loadOffers(this.$store.state.userId);
  },
};
</script>

<style lang="scss" scoped>
    .offer {
        &_list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
                margin-bottom: 15px;
            }
        }
    }
</style>