<template>
  <div class="kp_slide_container">
    <p class="slide_title">{{ title }}</p>
    <div class="slide_fields">
      <div class="slide_fields_item" v-for="field in fields" :key="field.name">
        <p class="slide_subtitle" v-if="field.value">{{ field.title }}</p>
        <div class="slide_value" v-html="field.value"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "fields"],
};
</script>