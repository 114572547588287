<template>
  <the-header v-if="!$route.params.hash"></the-header>
  <main v-if="$route.params.hash">
    <router-view></router-view>
  </main>
  <main v-else>
    <div class="row">
      <the-sidebar></the-sidebar>
      <section class="content">
        <router-view></router-view>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isViewOffer: false
    }
  }
};
</script>



<style lang="scss">
@import url(~@/assets/css/normalize.scss);
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  &:focus,
  &:active,
  &:hover {
    outline: none;
  }
}

p {
  margin: 7px 0;
}

$green: #aaab3a;

body,
html {
  font-family: "Raleway", Arial, sans-serif;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.button {
  height: 47px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #8d8e1b;
  background: $green;
  color: white;
  text-decoration: none;
  padding: 0 32px;
  transition: 0.1s;
  cursor: pointer;
  &:hover,
  &:focus {
    background: #8d8e1b;
    color: #ffffff;
  }
  &:active {
    background: #e1e1b4;
    color: #4e4e4e;
  }
  &_small {
    height: 33px;
    font-size: 14px;
    padding: 0 24px;
  }
  &_gray {
    border-color: #838383;
    background: #a4a4a4;
    &:hover,
    &:focus {
      background: #838383;
      color: #ffffff;
    }
    &:active {
      background: #c4c4c4;
      color: #ffffff;
    }
  }
  &_red {
    border-color: #a32121;
    background: #b36a6a;
    &:hover,
    &:focus,
    &:active {
      background: #a32121;
      color: #ffffff;
    }
  }
  &_framed {
    background: transparent;
    color: black;
    border-color: black;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: black;
      border-color: black;
      text-decoration: underline;
    }
  }
}
h1 {
  margin: 0 0 48px 0;
  font-size: 36px;
  font-weight: 500;
}
h2 {
  margin: 0 0 14px 0;
  font-size: 28px;
  font-weight: 500;
  background: #ffffd9;
  min-height: 50px;
  line-height: 1.4;
  padding: 6px 17px 6px 17px;
}
h3 {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  line-height: 1.3;
}
label {
  padding-left: 17px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  h3 {
    margin-bottom: 7px;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .button {
    margin-right: 28px;
  }
  input,
  textarea {
    background: #ffffff;
    border: 1px #828282 solid;
    padding: 12px 18px;
    &::placeholder {
      color: #c9c9c9;
    }
  }
  input {
    height: 47px;
    line-height: 47px;
    min-width: 426px;
  }
  textarea {
    width: 100%;
    resize: vertical;
    min-height: 150px;
  }
  span {
    flex: 0 0 100%;
    font-size: 12px;
    padding-left: 19px;
    margin-top: 7px;
    letter-spacing: 0.58px;
    line-height: 1.3;
  }
}
nav {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px #d6d6d6 solid;
  .button {
    &_prev {
      margin: 0 auto 0 0;
    }
    &_next {
      margin: 0 0 0 auto;
    }
  }
}
.notify {
  display: block;
  width: 100%;
  padding: 15px 17px;
  .wrap {
    padding: 15px 20px;
    border: 1px #b3b3b3 solid;
    background: #f1f1f1;
    p {
      font-size: 16px;
      margin: 0;
      &.title {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 10px 0;
      }
    }
  }
  &_warning {
    .wrap {
      border-color: rgb(255, 195, 85);
      background-color: rgb(255, 236, 200);
    }
  }
}
</style>