<template>
  <h1 v-html="offerTitle"></h1>
  <div class="offer_controls">
    <div class="has_hash" v-if="offerLink">
      <div class="has_hash_value">
        <p><span>&#10004;</span> КП сформировано</p>
      </div>
      <router-link
        :to="'/view/' + offerLink"
        class="button button_small"
        target="_blank"
        >Просмотр</router-link
      >
    </div>
    <a
      nohref
      class="button button_small"
      @click="createOfferLink(offerId)"
      v-else
      >Сформировать КП</a
    >

    <router-link
      :to="'/edit?offer_id=' + this.$route.params.offerId"
      class="button button_small button_framed"
      >Редактировать</router-link
    >

    <a nohref class="button button_small button_red">Удалить КП</a>

  </div>
  <div>
    <data-row
      v-for="row in preparedData"
      :key="row.name"
      :name="row.name"
      :title="row.title"
      :value="row.value"
    ></data-row>
    <div></div>
  </div>
</template>

<script>
import UIOfferDataRow from "../../../components/ui/UIOfferDataRow.vue";
export default {
  components: {
    "data-row": UIOfferDataRow,
  },
  data() {
    return {
      offerId: this.$route.params.offerId,
      offerTitle: ["title"],
      offerManagerId: null,
      offerFields: null,
      offerServiceId: null,
      serviceFields: null,
      preparedData: [],
      offerLink: null,
    };
  },
  methods: {
    loadOfferData(id) {
      // получение данных КП
      fetch(this.$store.state.rootPath + "/ajax/getOfferInfo.php?id=" + id, {
        method: "GET",
        headers: { "content-type": "appication/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          const offerData = data.message[0];
          this.offerFields = JSON.parse(offerData.fields);
          this.offerManagerId = offerData.manager;
          this.offerServiceId = offerData.service_id;
          this.loadServiceData(this.offerServiceId);
          if (
            offerData.link &&
            offerData.link !== "0" &&
            offerData.link !== ""
          ) {
            this.offerLink = offerData.link;
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    loadServiceData(id) {
      // Получение имени и полей услуги
      fetch(this.$store.state.rootPath + "/ajax/getService.php?id=" + id, {
        method: "GET",
        headers: { "content-type": "appication/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          const that = this;
          this.offerTitle =
            "<span>" +
            data.message[0].title +
            ": </span>" +
            this.offerFields.company_name;
          this.serviceFields = JSON.parse(data.message[0].fields);

          this.serviceFields.forEach(function (item) {
            let blockFields = item.blockFields;
            if (blockFields.length > 0) {
              blockFields.forEach(function (field) {
                let fieldName = field.fieldName; // имя поля
                let fieldValue = that.offerFields[fieldName]; // значение поля
                let fieldTitle = field.fieldTitle; // название поля
                that.preparedData.push({
                  name: fieldName,
                  value: fieldValue,
                  title: fieldTitle,
                });
                // console.log(fieldName);
                // console.log(that.offerFields[fieldName]);
              });
            }
          });
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    async createOfferLink(id) {
      const that = this;
      const formData = new FormData();
      formData.append("offer_id", id);
      formData.append("service_id", this.offerServiceId);

      await fetch(this.$store.state.rootPath + "/ajax/updateOfferLink.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then(function (data) {
          console.log(data);
          if (data.status) {
            // that.$router.push('/view/' + data.message);
            that.offerLink = data.message;
            console.log("Done");
          } else {
            console.log("Error");
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
  },
  created() {
    this.loadOfferData(this.$route.params.offerId);
  },
};
</script>

<style lang="scss" scoped>
.offer_controls {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 20px;
  .button {
    width: auto;
    margin-right: 15px;
  }
  .has_hash {
    display: flex;
    align-items: center;
    &_value {
      margin-right: 20px;
      p {
        margin: 0;
        color: gray;
        span {
          color: #8d8e1b;
        }
      }
    }
  }
}
.offer_data_row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  p.title {
    font-size: 18px;
    font-weight: 500;
  }
}
</style>