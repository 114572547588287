<template>
  <form @submit.prevent="submitForm" v-if="form_fields && !form_values">
    <div
      class="content_block"
      v-for="block in form_fields"
      :key="block.blockStep">
      <div>
        <h2>{{ block.blockTitle }}</h2>
        <label
          v-for="field in block.blockFields"
          :key="field.fieldName"
          :for="fieldName"
        >
          <h3>{{ field.fieldTitle }}</h3>

          <tiny-editor
            v-if="field.fieldType === 'textarea'"
            @change="getTinyData"
            :name="field.fieldName"
            api-key="eglpylzpl5vuggnchp04c2din4ratgqevieq9rpscgylsuky"
            :id="field.fieldName"
            :required="field.fieldRequired"
            :init="{
              height: 300,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help',
            }" />

          <input
            v-if="field.fieldType === 'text'"
            type="text"
            :id="field.fieldName"
            :name="field.fieldName"
            :required="field.fieldRequired"
            :placeholder="field.fieldPlaceholder"
          />
          <span>{{ field.fieldDescription }}</span>
        </label>
      </div>
    </div>
    <nav>
      <button type="submit" class="button button_next">Сохранить</button>
    </nav>
  </form>

  <form @submit.prevent="submitForm" v-if="form_fields && form_values">
    <div
      class="content_block"
      v-for="block in form_fields"
      :key="block.blockStep">
      <div>
        <h2>{{ block.blockTitle }}</h2>
        <label
          v-for="field in block.blockFields"
          :key="field.fieldName"
          :for="fieldName"
        >
          <h3>{{ field.fieldTitle }}</h3>

          <tiny-editor
            v-if="field.fieldType === 'textarea'"
            @change="getTinyData"
            :name="field.fieldName"
            api-key="eglpylzpl5vuggnchp04c2din4ratgqevieq9rpscgylsuky"
            :id="field.fieldName"
            :required="field.fieldRequired"
            :initial-value="prepareFieldValue(field.fieldName)"
            :value="prepareFieldValue(field.fieldName)"
            :init="{
              height: 300,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help',
            }" />

          <input
            v-if="field.fieldType === 'text'"
            type="text"
            :id="field.fieldName"
            :name="field.fieldName"
            :required="field.fieldRequired"
            :placeholder="field.fieldPlaceholder"
            :value="prepareFieldValue(field.fieldName)"
          />

          <span>{{ field.fieldDescription }}</span>
        </label>
      </div>
    </div>
    <nav>
      <button type="submit" class="button button_next">Сохранить</button>
    </nav>
  </form>

  <div class="notify notify_warning" v-if="error">
    <div class="wrap">
      <p class="title">Что-то пошло не так</p>
      <p>Отсутствуют данные для формы</p>
    </div>
  </div>

</template>

<script>
// import tinymce from "@tinymce/tinymce-vue";
export default {
  props: ["service_id", "form_fields", "form_values"],
  computed: {
    // prepareFormFields() {
    //   return JSON.parse(this.form_fields);
    // },
    prepareFormValues() {
      return JSON.parse(this.form_values);
    },
  },
  methods: {
    getTinyData(_, editor) {
        editor.save();
    },
    submitForm(e) {
      const that = this;

      let formData = new FormData(e.target);

      formData.append("manager", this.$store.state.userId);
      formData.append("service_id", this.service_id);
      
      if (this.$route.query.offer_id) {
        formData.append('offer_id', this.$route.query.offer_id);
      }

      fetch(this.$store.state.rootPath + "/ajax/updateOffers.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then(function (data) {
          console.log(data);
          if (data.status) {
            that.$router.push("/offers");
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    prepareFieldValue(field) {
      return this.prepareFormValues[field];

    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 100px;
  border-top: 1px #d6d6d6 solid;
  .button {
    &_prev {
      margin: 0 auto 0 0;
    }
    &_next {
      margin: 0 0 0 auto;
    }
  }
}
</style>