<template>
    <header class="header">
        <div class="row">
            <div class="header_logo">
                <a href="/">
                    <img src="@/assets/img/logo.svg" alt="Онлайн КП | STARTA">
                </a>
            </div>
            <div class="header_buttons">
                <li class="button button_small button_gray"><router-link to="/offers">Мои КП</router-link></li>
                <li class="button button_small"><router-link to="/edit">Создать КП</router-link></li>
                <!-- <a href="#" class="button button_small">Создать КП</a>
                <a href="#" class="button button_small button_gray">Мои КП</a> -->
            </div>
        </div>
    </header>
</template>

<script>

</script>

<style scoped lang="scss">
    .header {
        border-bottom: 1px #E0E0E0 solid;
        >.row {
            height: 89px;
            align-items: center;
        }
        &_logo {
            flex: 0 0 310px;
            max-width: 310px;
            width: 310px;
            a {
                display: table;
                margin-top: 5px;
            }
        }
        &_buttons {
            flex: 0 0 720px;
            max-width: 720px;
            width: 720px;
            display: flex;
            justify-content: flex-end;
            margin-top: 2px;
            .button {
                margin-right: 25px;
                a {
                    color: white;
                }
                &:last-of-type {
                    margin-right: 40px;
                }
            }
        }
    }
</style>
