<template>
    <h1>404 &ndash; Страница не найдена</h1>
    <div class="not-found">
        <p>Такой страницы не существует, сорян (<br>Если хотите, можете обратиться к администратору, но лучше не надо</p>
        <router-link to="/" class="button button_small">Это не справедливо!</router-link>
    </div>
</template>

<style lang="scss" scoped>
.not-found {
    .button {
        margin: 20px 0;
        width: 250px;
    }
}
</style>