<template>
  <li class="offer_list_item">
    <div class="wrap">
      <div class="info">
        <p class="company_name">
          <router-link
          :to="'/offers/' + offer_id"
          >{{ company_name }}</router-link
        ></p>
        <p class="service"><span>Услуга:</span> {{ serviceInfo.title }}</p>
        <p class="manager_name">
          <span>Менеджер:</span>
          {{ managerInfo.firstname + " " + managerInfo.lastname }}
        </p>
      </div>
      <div class="controls">
        <router-link
          :to="'/edit?offer_id=' + offer_id"
          class="button button_small"
          >Редактировать</router-link
        >
      </div>
    </div>
  </li>
</template>

<script>
export default {
  data() {
    return {
      managerInfo: [],
      serviceInfo: []
    };
  },
  props: ["company_name", "service_id", "manager_id", "offer_id"],
  methods: {},
  computed: {},
  mounted() {
    // Получение данных менеджера КП
    fetch(this.$store.state.rootPath + "/ajax/getManager.php?id=" + this.manager_id, {
      method: "GET",
      headers: { "content-type": "appication/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.managerInfo = data.message[0];
      })
      .catch(function (err) {
        console.log("Error: ", err);
      });

    // Получение данных об услуге КП
    fetch(this.$store.state.rootPath + "/ajax/getService.php?id=" + this.service_id, {
      method: "GET",
      headers: { "content-type": "appication/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        this.serviceInfo = data.message[0];
      })
      .catch(function (err) {
        console.log("Error: ", err);
      });
  },
};
</script>

<style lang="scss" scoped>
.offer {
  &_list {
    &_item {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      .wrap {
        display: flex;
        width: 100%;
        justify-content: space-between;
        // align-items: center;
        flex-wrap: nowrap;
        background: #f8f8f8;
        border: 1px #e1e1e1 solid;
        padding: 15px 20px;
        .info {
          flex: 0 0 70%;
          width: 70%;
          max-width: 70%;
          display: flex;
          flex-wrap: wrap;
          p {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            margin: 0 0 4px 0;
            font-size: 15px;
            &.company_name {
              font-size: 20px;
              margin-bottom: 10px;
              font-weight: 500;
            }
            a {
              color: black;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
            span {
              color: gray;
            }
          }
        }
        .controls {
          flex: 0 0 30%;
          width: 30%;
          max-width: 30%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          .button {
            margin-bottom: 5px;
            // width: 100%;
          }
        }
      }
    }
  }
}
</style>