import {createApp} from 'vue'
import {createRouter,createWebHistory} from 'vue-router';
import {createStore} from 'vuex';
import App from './App.vue'
import TinyEditor from "@tinymce/tinymce-vue";

import UIOfferListItem from './components/ui/UIOfferListItem.vue';

import TheHeader from './components/layouts/TheHeader.vue';
import TheSidebar from './components/layouts/TheSidebar.vue';
import EditOffer from './pages/edit/EditOffer.vue';
import OffersList from './pages/offers/OffersList.vue';
import OfferDetail from './pages/offers/offer_detail/OfferDetail.vue';
import ViewOffer from './pages/view/ViewOffer.vue';
import NotFound from './pages/NotFound.vue';

const app = createApp(App);

const store = createStore({
    state() {
        return {
            offers: [],
            userId: 1,
            rootPath: 'https://kp.starta.ru'
        }
    }
});

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes: [{
            path: '/',
            redirect: '/offers'
        }, {
            path: '/edit',
            component: EditOffer
        },
        {
            path: '/offers',
            component: OffersList
        },
        {
            path: '/view/:hash',
            component: ViewOffer
        },
        {
            path: '/offers/:offerId',
            component: OfferDetail
        },
        {
            path: '/:notFound(.*)',
            component: NotFound 
        }
    ]
});

app.component("tiny-editor", TinyEditor);
app.component('the-header', TheHeader);
app.component('the-sidebar', TheSidebar);
app.component('edit-offer', EditOffer);
app.component('ui-offer-list-item', UIOfferListItem);

app.use(router);
app.use(store);

app.mount('#app');