<template>
  <div class="cross_elements">
    <div class="row">
      <div class="cross_elements_left">
        <p>Помогаем брендам расти и меняться</p>
      </div>
      <div class="cross_elements_right">
        <a href="https://starta.ru" target="_blank">www.starta.ru</a>
        <div class="circle"></div>
      </div>
    </div>
  </div>

  <section class="main">
    <div class="row">
      <div class="main_bg">
        <div class="main_bg_circles">
          <div class="main_bg_circles_inner">
            <div class="main_bg_circles_inner_elem"></div>
          </div>
          <div class="main_bg_circles_outer">
            <div class="main_bg_circles_outer_elem"></div>
          </div>
        </div>
      </div>
      <div class="main_slogan">
        <p>
          <span>AGENCY OF</span> агентство<br />
          <span>MARKETING & VISUAL</span> маркетинговых и визуальных<br />
          <span>COMMUNICATION</span> коммуникаций
        </p>
      </div>
      <div class="main_logo"></div>
      <div class="main_grunge_circle"></div>
    </div>
  </section>

  <section class="kp_title">
    <div class="row">
      <div class="kp_title_bg">
        <div class="kp_title_bg_figure"></div>
        <div class="kp_title_bg_human"></div>
        <div class="kp_title_bg_arrows">
          <div class="kp_title_bg_arrows_item"></div>
          <div class="kp_title_bg_arrows_item"></div>
        </div>
      </div>
      <div class="kp_title_h1">
        <h1 v-html="kp_title"></h1>
      </div>
    </div>
  </section>

  <section class="kp_slide">
    <div class="row">
      <div class="kp_slide_bg">
        <div class="kp_slide_bg_big_circle"></div>
        <div class="kp_slide_bg_donut"></div>
        <div class="kp_slide_bg_donut_hhalf"></div>
      </div>

      <view-item
        v-for="block in kp_prepared_data"
        :key="block"
        :fields="block.fields"
        :title="block.title"
      ></view-item>

      <div class="kp_slide_container kp_slide_container_total">
        <div class="kp_slide_container_total_controls">
          <a class="breif" href="https://starta.ru/breif-54" target="_blank"
            >Заполнить бриф</a
          >
          <p>
            или позвонить нам
            <a href="tel:88002503021" class="phone">8 (800) 250-30-21</a>
          </p>
        </div>
      </div>
    </div>
  </section>

  <footer>
    <div class="row"></div>
  </footer>
</template>

<script>
import UIViewSlideItem from "../../components/ui/UIViewSlideItem.vue";

export default {
  data() {
    return {
      kp_title: "<b>Коммерческое предложение</b>",
      kp_id: null,
      kp_company_name: null,
      kp_fields: [],
      kp_manager_id: null,
      kp_service_id: null,
      kp_service_name: null,
      kp_service_data: null,
      kp_service_fields: null,
      kp_prepared_data: [],
    };
  },
  components: {
    "view-item": UIViewSlideItem,
  },
  methods: {
    async loadServiceData(id) {
      // Получение имени и полей услуги
      await fetch(this.$store.state.rootPath + "/ajax/getService.php?id=" + id, {
        method: "GET",
        headers: { "content-type": "appication/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          const that = this;
          that.kp_service_data = data.message[0];
          that.kp_service_name = this.kp_service_data.title;

          const tmpArray = [];
          this.kp_service_fields = JSON.parse(data.message[0].fields);
          this.kp_service_fields.forEach(function (item) {
            let blockFields = item.blockFields;
            let blockTitle = item.blockTitle;
            if (blockTitle !== "Данные компании") {
              const tmpArrayToo = [];
              if (blockFields.length > 0) {
                blockFields.forEach(function (field) {
                  let fieldName = field.fieldName; // имя поля
                  let fieldValue = that.kp_fields[fieldName]; // значение поля
                  let fieldTitle = field.fieldTitle; // название поля
                  let fieldType = field.fieldType; // тип поля
                  if (fieldValue !== "") {
                    tmpArrayToo.push({
                      name: fieldName,
                      value: fieldValue,
                      title: fieldTitle,
                      type: fieldType,
                    });
                  }
                });
              }
              if (tmpArrayToo.length > 0) {
                tmpArray.push({ title: blockTitle, fields: tmpArrayToo });
              }
            }
          });

          that.kp_prepared_data = tmpArray;
          console.log(that.kp_prepared_data);
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    async getOfferData(hash) {
      //console.log(hash);
      // получение данных КП по хешу
      await fetch(this.$store.state.rootPath + "/ajax/getOfferInfo.php?hash=" + hash, {
        method: "GET",
        headers: { "content-type": "appication/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.kp_id = data.message[0].id;
          this.kp_fields = JSON.parse(data.message[0].fields);
          this.kp_company_name = this.kp_fields["company_name"];
          this.kp_service_id = data.message[0].service_id;
          this.kp_manager_id = data.message[0].manager;

          if (this.kp_service_id) {
            this.loadServiceData(this.kp_service_id);
          }

          if (this.kp_company_name) {
            this.kp_title =
              '<b>Коммерческое предложение</b> для компании <span class="company_name">' +
              this.kp_company_name +
              '</span><br><span class="service_name">Услуга: ' +
              this.kp_service_name +
              "</span>";
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
      return;
    },
  },
  created() {
    this.getOfferData(this.$route.params.hash);
  },
  watch: {
      kp_service_data() {
        if (!this.kp_service_name) {
            this.kp_service_name = this.kp_service_data.title;
        }
      }
  }
};
</script>

<style lang="scss" scoped>
@import url(~@/assets/css/app.css);
</style>